<template>
  <el-popover
    :value="visible"
    popper-class="serviceRatePopover position-fixed"
  >
    <div class="flex flex-grow-1 gap-1 mb-10 flex-column">
      <div
        v-tooltip="popoverTitle"
        class="prompt-notice cut mb-1"
      >
        {{ popoverTitle }}
      </div>

      <div class="flex">
        <m-buttons-group
          :value="rateTypeValue"
          radio
          :use-custom-result="DEFAULT_RESULT_TYPES.SIMPLE"
          :items="currencyTypes"
          :m-fixed-height="false"
          @change="$updateSync('rateTypeValue', $event)"
        />

        <m-input
          :value="rateValue"
          class="flex-grow-1"
          type="number"
          style="width: 230px"
          :min="0"
          :m-fixed-height="false"
          @change="$updateSync('rateValue', $event)"
        />
      </div>
    </div>
    <div class="serviceRatePopover__buttons flex justify-content-between">
      <div>
        <m-button
          template="short_save"
          @click="$emit('setRate')"
        />
        <m-button
          template="short_clear"
          :disabled="!isRateActive"
          @click="$emit('resetRate')"
        />
      </div>

      <div>
        <m-button
          template="close"
          @click="$emit('close')"
        />
      </div>
    </div>
  </el-popover>
</template>

<script>
import { GLOBAL_CURRENCY_TYPES } from '@/_global_scripts/GLOBAL_CONSTS'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'

const X_SHIFT = 100
const Y_SHIFT = 10
const POPOVER_WIDTH = 400
const POPOVER_HEIGHT = 130

const calculateXY = (coordinates) => {
  const { clientWidth, clientHeight } = document.documentElement
  const finalX = coordinates.x + POPOVER_WIDTH >= clientWidth
    ? clientWidth - POPOVER_WIDTH
    : coordinates.x - X_SHIFT

  const finalY = coordinates.y + POPOVER_HEIGHT + Y_SHIFT >= clientHeight
    ? clientHeight - POPOVER_HEIGHT - Y_SHIFT
    : coordinates.y + Y_SHIFT

  return { finalX, finalY }
}

export default {
  name: 'ServiceRatePopover',
  components: { MButton, MInput, MButtonsGroup },
  props: {
    visible: Boolean,
    x: PropsTypes.Number(0),
    y: PropsTypes.Number(0),
    popoverTitle: PropsTypes.String(),
    rateValue: PropsTypes.Number(0),
    rateTypeValue: PropsTypes.String(GLOBAL_CURRENCY_TYPES.PERCENT),
    isRateActive: Boolean,
  },

  data () {
    return {
      popoverSelector: null,
      popoverSelectorInput: null,
      DEFAULT_RESULT_TYPES,
    }
  },

  computed: {
    currencyTypes () {
      return [
        { id: GLOBAL_CURRENCY_TYPES.PERCENT, title: '%' },
        { id: GLOBAL_CURRENCY_TYPES.FIXED, title: gon.localization.currency.symbol_character },
      ]
    },

    coordinates () {
      return { x: this.x, y: this.y }
    },
  },

  watch: {
    coordinates (to) {
      if (!this.popoverSelector) { return }

      const { finalX, finalY } = calculateXY(to)

      this.popoverSelector.style.left = `${finalX}px`
      this.popoverSelector.style.top = `${finalY}px`

      this.setFocus()
    },

    visible (to) {
      if (!to) { return }
      this.setFocus()
    },
  },

  mounted () {
    this.popoverSelector = document.querySelector('.serviceRatePopover')
    this.popoverSelectorInput = document.querySelector('.serviceRatePopover').querySelector('input')
  },

  methods: {
    setFocus () {
      this.$nextTick(() => {
        this.popoverSelectorInput.select()
      })
    },
  },
}
</script>
