import { SERVICES_PERFORMERS } from '@/vue_present/ServiceRate/consts/const'

/**
 * @param {string} performerType
 * @return {{fetchCategory: Function, update: Function}|null}
 */
export const getRoutes = (performerType) => {
  switch (performerType) {
    case SERVICES_PERFORMERS.USER: return {
      fetchCategory: Routes.user_services_query_path,
      update: Routes.services_user_path,
    }

    case SERVICES_PERFORMERS.REFERRAL: return {
      fetchCategory: Routes.referral_services_query_path,
      update: Routes.referral_services_path,
    }

    default: return null
  }
}

/**
 * @param performerType
 * @return {{services: string, serviceCategories: string, mappedNodes: {referralServicesAttributes: *[], referralServiceCategoriesAttributes: *[]}}|null|{services: string, serviceCategories: string, mappedNodes: {userServiceCategoriesAttributes: *[], userServicesAttributes: *[]}}}
 */
export const getRewarderNodeFields = (performerType) => {
  switch (performerType) {
    case SERVICES_PERFORMERS.USER: return {
      mappedNodes: {
        userServicesAttributes: [],
        userServiceCategoriesAttributes: [],
      },
      services: 'userServicesAttributes',
      serviceCategories: 'userServiceCategoriesAttributes',
    }

    case SERVICES_PERFORMERS.REFERRAL: return {
      mappedNodes: {
        referralServicesAttributes: [],
        referralServiceCategoriesAttributes: [],
      },
      services: 'referralServicesAttributes',
      serviceCategories: 'referralServiceCategoriesAttributes',
    }

    default: return null
  }
}

/**
 * @param performerType
 * @return {{services: string, serviceCategories: string}|null}
 */
export const getRewarderFieldsWhenUpdate = (performerType) => {
  switch (performerType) {
    case SERVICES_PERFORMERS.USER: return {
      serviceCategories: 'userServiceCategories',
      services: 'userServices',
    }

    case SERVICES_PERFORMERS.REFERRAL: return {
      serviceCategories: 'referralServiceCategories',
      services: 'referralServices',
    }

    default: return null
  }
}
