<template>
  <div class="service-rate-tree flex flex-column">
    <el-tree
      class="pb-15"
      :node-key="treeMeta.nodeKey"
      :props="treeMeta.props"
      :load="loadNode"
      :render-content="treeMeta.servicesRatesTreeRenderContent"
      :indent="25"
      :empty-text="treeMeta.emptyText"
      lazy
    />

    <service-rate-popover
      :visible="popoverMeta.visible"
      :x="popoverMeta.x"
      :y="popoverMeta.y"
      :popover-title="popoverMeta.title"
      :rate-value.sync="popoverMeta.rate"
      :rate-type-value.sync="popoverMeta.rateType"
      :is-rate-active="popoverMeta.isRateActive"
      @close="popoverMeta.visible = false"
      @setRate="setRate(currentNode, popoverMeta.rate, popoverMeta.rateType)"
      @resetRate="setRate(currentNode)"
    />

    <service-rate-sync-modal
      v-if="isPerformerTypeIsUser"
      :performer-id="performerId"
      :presenter="presenter"
      @registerOpenModal="openSyncModal = $event"
    />
  </div>
</template>

<script>
import { GLOBAL_CATEGORY_ROOT_ID, GLOBAL_CURRENCY_TYPES } from '@/_global_scripts/GLOBAL_CONSTS'
import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'
import { servicesRatesTreeRenderContent } from '@/vue_present/ServiceRate/helpers/servicesRatesTreeRenderContent'
import { serviceRateLogicMixin } from '@/vue_present/ServiceRate/helpers/serviceRateLogicMixin'
import ServiceRatePopover from '@/vue_present/ServiceRate/components/ServiceRatePopover.vue'
import { confirmCategoryChangingDialog } from '@/vue_present/ServiceRate/helpers/confirmCategoryChangingDialog'
import ServiceRateSyncModal from './components/ServiceRateSyncModal.vue'
import { MServicesRatesPresenter } from '@/_api/MServicesRates/MServicesRatesPresenter'
import { SERVICES_PERFORMERS } from './consts/const'

export default {
  name: 'ServiceRate',

  components: {
    ServiceRatePopover,
    ServiceRateSyncModal,
  },

  mixins: [
    serviceRateLogicMixin,
  ],

  props: {
    performerId: { type: Number, required: true },
    performerType: { type: String, required: true },
  },

  data () {
    return {
      treeMeta: {
        props: {
          label: 'title',
          isLeaf: 'leaf',
          children: 'childrenNodes',
        },
        nodeKey: 'id',
        emptyText: t('loading_data'),

        servicesRatesTreeRenderContent: (h, data) => servicesRatesTreeRenderContent.call(this, h, data),
      },

      popoverMeta: {
        visible: false,
        rate: 0,
        rateType: GLOBAL_CURRENCY_TYPES.PERCENT,
        isRateActive: false,
        title: null,
        x: 0,
        y: 0,
      },

      /**
       * @type {Record<number, ServiceRateCategory | ServiceRateEntryType>} treeItemsFlatMap
       */
      treeItemsFlatMap: {
        [GLOBAL_CATEGORY_ROOT_ID]:
            new ServiceRateCategory({ id: GLOBAL_CATEGORY_ROOT_ID, rate: null, rateType: null }),
      },

      /**
       * @type {ServiceRateEntryType | ServiceRateCategory} currentNode
       */
      currentNode: null,
      presenter: new MServicesRatesPresenter(this.performerType),
      isRatesSynkingEnabled: gon.application.sync_salary_entries_on_user_service_change,
    }
  },

  computed: {
    isPerformerTypeIsUser () {
      return this.performerType === SERVICES_PERFORMERS.USER
    },

    canSyncRate () {
      return this.isPerformerTypeIsUser && this.isRatesSynkingEnabled
    },
  },

  methods: {
    openSyncModal () {},

    askToSyncRateIfPossible (node) {
      if (this.canSyncRate) { this.openSyncModal(node) }
    },

    /**
     * Проверка необходимости синхронизации при активации через чекбокс
     * @param {ServiceRateCategory | ServiceRateEntryType} node
     * @return {boolean}
     */
    async checkSyncNeedWhenActivatedByCheckbox (node) {
      if (node.leaf || !this.canSyncRate) { return false }

      const { isDisabled } = await this.presenter
        .checkCategoryCompletelyDisabled(this.performerId, node.getId())

      return Boolean(isDisabled)
    },

    /**
     * Включение/выключение оказываемой услуги чекбоксом
     * @param {ServiceRateCategory | ServiceRateEntryType} serviceRateItem
     * @return {Promise<void>}
     */
    async onServiceItemCBClick (serviceRateItem) {
      if (!await confirmCategoryChangingDialog(serviceRateItem)) { return }

      const rateWasBefore = !serviceRateItem.noRate()

      rateWasBefore
        ? await this.onResetRateValue(serviceRateItem)
        : await this.onSetRateValue({
          node: serviceRateItem,
          rate: 0,
          rateType: GLOBAL_CURRENCY_TYPES.PERCENT,
          withSync: await this.checkSyncNeedWhenActivatedByCheckbox(serviceRateItem),
        })

      this.afterProcessing()
    },

    /**
     * Установка/удаление значения у услуги
     * @param {ServiceRateCategory | ServiceRateEntryType} serviceRateItem
     * @param {Number?} rate
     * @param {("percent" | "fixed")?} rateType
     * @return {Promise<void>}
     */
    async setRate (serviceRateItem, rate, rateType) {
      if (!await confirmCategoryChangingDialog(serviceRateItem)) { return }

      rate === undefined || rateType === undefined
        ? await this.onResetRateValue(serviceRateItem)
        : await this.onSetRateValue({ node: serviceRateItem, rate, rateType })

      this.afterProcessing()
    },

    /**
     * Обработка клика по Rate
     * @param {MouseEvent} e
     * @param {ServiceRateCategory | ServiceRateEntryType} data
     */
    onRateClick ({ e, data }) {
      this.popoverMeta.x = e.clientX
      this.popoverMeta.y = e.clientY
      this.popoverMeta.title = data.title
      this.popoverMeta.rate = Utils.toMoney(data.rate) || 0
      this.popoverMeta.rateType = data.rateType || GLOBAL_CURRENCY_TYPES.PERCENT
      this.popoverMeta.isRateActive = !data.noRate()

      this.currentNode = data

      this.popoverMeta.visible = true
    },

    afterProcessing () {
      this.popoverMeta.visible = false
      this.popoverMeta.title = ''
      this.popoverMeta.rate = 0
      this.popoverMeta.rateType = GLOBAL_CURRENCY_TYPES.PERCENT
      this.popoverMeta.isRateActive = false
    },

    /**
     * Загрузка узла
     * @param {ServiceRateCategory} data
     * @param {Function} resolve - колбак от el-tree
     * @return {Promise<void>}
     */
    async loadNode ({ data }, resolve) {
      const nodeId = data?.getId() || GLOBAL_CATEGORY_ROOT_ID
      const categoriesResponse = await this.presenter.fetchCategory(this.performerId, nodeId)
      if (categoriesResponse?.errors) { return }

      const mappedItems = this.__fillTreeItemsFlatMap(data, categoriesResponse)
      resolve(mappedItems)
    },

    /**
     * Установка нового значения узла
     * @param {{
     *  node: ServiceRateCategory | ServiceRateEntryType,
     *  rate: number,
     *  rateType: "percent" | "fixed",
     *  withSync: boolean,
     * }}
     * @return {Promise<void>}
     */
    async onSetRateValue ({ node, rate, rateType, withSync = true }) {
      const rawNode = node.generateSingleQueryData(rate, rateType)
      const { serviceRateId } = rawNode

      this.treeItemsFlatMap[rawNode.rawId].loadingStart()
      const { errors, serviceCategories, services } =
          await this.presenter.update(this.performerId, [rawNode])

      if (errors) { return }

      this.__resetServiceRateIds(node, serviceCategories, services)

      /**
       * поменялась концепция обновления
       * бекенд больше не отправляет назад service,
       * который был обновлён сменой rate или rateType,
       * поэтому this.__resetServiceRateIds::node.unsetChildServiceRateId() работает несколько иначе
       */
      if (serviceRateId) { node.setServiceRateId(serviceRateId) }

      node.setRate(rate, rateType)

      this.treeItemsFlatMap[rawNode.rawId].loadingStop()

      if (withSync) { this.askToSyncRateIfPossible(node) }
    },

    /**
     * Сброс значения узла
     * @param {ServiceRateCategory | ServiceRateEntryType} node
     * @return {Promise<void>}
     */
    async onResetRateValue (node) {
      const rawNodes = node.generateQueryData()

      rawNodes.forEach((rawNode) => this.treeItemsFlatMap[rawNode.rawId].loadingStart())
      const { errors, serviceCategories, services } =
        await this.presenter.update(this.performerId, rawNodes)

      if (errors) { return }

      this.__resetServiceRateIds(node, serviceCategories, services, true)

      node.unsetRate()
      node.unsetParentRate()
      node.unsetChildRate()

      rawNodes.forEach((rawNode) => this.treeItemsFlatMap[rawNode.rawId].loadingStop())

      this.askToSyncRateIfPossible(node)
    },
  },
}
</script>
