<template>
  <m-modal
    :visible="visible"
    class="serivce-rate-sync-modal"
    :dialog-title="t('salary_entry_rate_sync')"
    width="500px"
    @close="close"
  >
    <template #body>
      <div class="mb-15">
        {{ t('salary_entry_rate_sync_question_by_date') }}
      </div>
      <m-date-picker
        v-model="date"
        :label="t('common.date')"
        type="date"
        :full-width="false"
        value-format="yyyy-MM-dd"
        required
        validator-name="date"
        @registerValidator="onRegisterValidator"
      />
    </template>

    <template #footer-right-action>
      <m-button
        icon="ready"
        type="success"
        :text="t('yes')"
        @click="syncRates"
      />
    </template>

    <template #footer-right>
      <m-button
        icon="close"
        type="primary"
        :text="t('no')"
        @click="close"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from 'src/vue_present/mixins/ValidationParentMixin'
import { MServicesRatesPresenter } from 'src/_api/MServicesRates/MServicesRatesPresenter'

export default {
  name: 'ServiceRateSyncModal',

  components: {
    MModal,
    MDatePicker,
    MButton,
  },

  mixins: [ValidationParentMixin],

  props: {
    performerId: { type: Number, required: true },
    presenter: { type: MServicesRatesPresenter, required: true },
  },

  emits: ['registerOpenModal'],

  data () {
    return {
      visible: false,
      date: '',
      node: null,
    }
  },

  mounted () {
    this.$emit('registerOpenModal', this.open)
  },

  methods: {
    resetData (node = null) {
      this.date = Utils.getBaseFormattedDate()
      this.node = node
    },

    open (node) {
      this.resetData(node)
      this.visible = true
    },

    close () {
      this.visible = false
    },

    async syncRates () {
      if (this.hasErrors()) { return }

      const syncArgs = [
        this.performerId,
        this.node.getId(),
        this.date,
      ]

      const syncMethod = this.node.leaf
        ? this.presenter.syncByEntryType
        : this.presenter.syncByCategory

      const res = await syncMethod.apply(this.presenter, syncArgs)
      if (res?.errors) { return }

      this.close()
    },
  },
}
</script>
