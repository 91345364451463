import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { servicesRatesAdapter } from '@/_api/MServicesRates/servicesRatesAdapter'
import { getRoutes } from '@/_api/MServicesRates/_helpers'

export class MServicesRatesPresenter extends MPresenterBase {
  constructor (performerType = '') {
    if (!performerType) {
      throw new Error('Не указана сущность')
    }

    const routes = getRoutes(performerType)
    if (!routes) {
      throw new Error('Не найдены маршруты')
    }

    super({
      entity: 'servicesCategory',
      location: 'MServicesRatesPresenter',
      routes: { one: routes.fetchCategory, all () {} },
    })

    this.performerType = performerType
    this.routes = routes
  }

  fetchCategory (performerId, categoryId = 0) {
    const options = {
      url: this.routes.fetchCategory(),
      data: camelToSnake({ entityId: performerId, categoryId }),
    }

    const notification = new MRequestNotification(
      'MServicesRatesPresenter::fetchCategory',
      'fetchMessage',
      'servicesCategory'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse(servicesRatesAdapter.toClient))
      .catch(MRequestError.onRequest(notification))
  }

  update (performerId, nodes) {
    const options = {
      url: this.routes.update(performerId),
      data: { [this.performerType]: servicesRatesAdapter.toServer(this.performerType, nodes, performerId) },
    }

    const notification = new MRequestNotification(
      'MServicesRatesPresenter::update',
      'updateMessage',
      'servicesCategory'
    )

    const toClientAdapter = (data) => servicesRatesAdapter.update(this.performerType, snakeToCamel(data))

    return this.patch(options, toClientAdapter).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * Проверка, что внутри категории все услуги отключены (включая услуги во вложенных категориях)
   * @param {number} userId
   * @param {number} categoryId
   * @return {Promise<IApiErrors | { isDisabled: boolean }>}
   */
  checkCategoryCompletelyDisabled (userId, categoryId) {
    const options = {
      url: Routes.check_existence_by_category_api_internal_user_services_path(),
      data: JSON.stringify(camelToSnake({ userId, categoryId })),
      contentType: 'application/json',
      dataType: 'json',
    }

    return this.post(options).promise
      .then(MRequestSuccess.onResponse(servicesRatesAdapter.disabledCategoryCheckToClient))
      .catch(MRequestError.onRequest())
  }

  syncByEntryType (userId, entryTypeId, dateStart) {
    return this.__sync({
      url: Routes.sync_by_entry_type_api_internal_salary_entries_path(),
      data: { userId, dateStart, entryTypeId },
    })
  }

  syncByCategory (userId, categoryId, dateStart) {
    return this.__sync({
      url: Routes.sync_by_entry_types_category_api_internal_salary_entries_path(),
      data: { userId, dateStart, categoryId },
    })
  }

  __sync (options) {
    const allOptions = {
      ...options,
      data: JSON.stringify(camelToSnake(options.data)),
      contentType: 'application/json',
      dataType: 'json',
    }

    const notification = new MRequestNotification(
      'MServicesRatesPresenter::syncOldEntries',
      'updateMessage',
      'userServiceRate'
    )

    return this.post(allOptions).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }
}
