import { DEFAULT_CURRENT_PAGE } from '@/vue_components/store/modules/filters_base'
import { MListServiceLogic } from '@/_api/_requests/MListServiceApi/MListServiceLogic'
import { IDataItem, IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'
import { IMListServiceApi } from '@/_declarations/IMListServiceApi'
import { MCommonListStrategy } from '@/_api/_requests/MListServiceApi/strategies/MCommonListStrategy'
import { IListStrategy } from '@/_api/_requests/MListServiceApi/strategies/IListStrategy'

export class MListServiceApi<DataItem, FiltersMap>
  extends MListServiceLogic<DataItem, FiltersMap>
  implements IMListServiceApi<DataItem, FiltersMap> {
  strategy!: IListStrategy<DataItem, FiltersMap>

  constructor (
    config: IMListServiceApiConfig<DataItem, FiltersMap>
  ) {
    super(config)

    this.strategy = config?.Strategy
      ? new config.Strategy(this, config)
      : new MCommonListStrategy(this, config)
  }

  get data (): Array<IDataItem<DataItem>> { return this._data }

  get totals (): IDataItem<DataItem> { return this._totals }

  get filters (): FiltersMap { return this._filters }

  get isFiltered (): boolean { return this.filtered }

  get searchQuery (): string { return this._searchQuery }

  get currentPage (): number { return super.currentPage }

  get totalPages (): number { return this._totalPages }

  get totalItems (): number { return this._totalItems }

  get loading (): boolean { return this._loading }

  setPage (page = DEFAULT_CURRENT_PAGE, withUpdate = true): Promise<void> {
    super.setPage(page)

    if (withUpdate) { return this.fetchAll() }
  }

  resetFilters (filters = this.initialFilters): Promise<void> {
    super.resetFilters(filters)

    return this.setPage()
  }

  refreshFilters (): Promise<void> {
    super.refreshFilters()

    return this.setPage()
  }

  setFilterValue (
    filterName: keyof FiltersMap,
    value: any,
    withUpdate = true
  ): Promise<void> {
    super.setFilterValue(filterName, value)
    super.setPage()

    if (withUpdate) { return this.fetchAll() }
  }

  search (searchQuery: string, standaloneSearch = true): Promise<void> {
    if (this.isSearchRepeatedOrEmpty(searchQuery)) { return }
    super.search(searchQuery)

    if (standaloneSearch) {
      return this.fetchAll({} as FiltersMap)
    }

    return this.fetchAll()
  }

  fetchAll (filters: FiltersMap = this._filters): Promise<void> {
    this.startLoading()

    const payload = {
      ...filters,
      limit: this.limit,
      offset: this.offset,
      searchQuery: this.searchQuery,
    }

    return this.strategy.fetchAll(payload)
  }
}
